/* Override label styles because product wanted them outside of the inputs */
.form-field label,
.form-field label[data-shrink="true"] {
    word-wrap: break-word;
    position: static;
    transform: none;
    font-size: 16px;
    text-overflow: initial;
    white-space: normal;
    max-width: 100%;
    color: #757575;
}

/* Make padding even since we no longer have label inside of input */
.MuiFormControl-root .MuiInputBase-input,
.form-field.form-field.form-field--list .MuiNativeSelect-select,
.MuiFormControl-root .MuiSelect-select {
    padding: 12px;
}

/* Remove padding here, because padding is added on inner select already from above style on base-input */
.form-field--long-text .MuiInputBase-root {
    padding: 0;
}

/* Restrict wrapper width so checkbox long labels will wrap */
.MuiFormControl-root {
    max-width: 100%;
}

.form-field--checkbox .MuiFormControlLabel-label {
    /* 42px is width of checkbox. Make sure the label doesn't get longer than rest of container */
    max-width: calc( 100% - 42px );
}

/* 
 * Get rid of spacing that was pushing calendar icon and price field dollar sign down. 
 * Not needed now that we don't have extra whitespace at top for label 
 */
.MuiFormControl-root .MuiInputAdornment-root {
    padding-top: 0;
}

.MuiInputBase-root.MuiInputBase-root {
    background-color: #f5f5f5;
}

.MuiInputBase-root.MuiInputBase-root::before,
.MuiInputBase-root.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before {
    border-color: #bdbdbd;
}

.MuiMenu-list .MuiMenuItem-root,
.MuiFormControl-root .MuiNativeSelect-select option {
    white-space: normal;
}

.form-field {
    margin-bottom: 14px;
}

.check-box--invalid .MuiSvgIcon-root {
    color: rgb(211, 47, 47);
}

.form-submit-button.form-submit-button {
    text-transform: none;
    height: 45px;
    font-weight: bold;
}
    