.header-wrapper {
    margin-bottom: 25px;
}

.header__text {
    text-align: center;
}

.header-logo {
    max-width: 100%;
    margin-bottom: 10px;
}

.sub-header {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-header.confirmation {
    margin: 20px 0;
}
.sub-header:nth-of-type(2) {
    margin: 20px 0;
    margin-left: 10px;
}

.sub-header .sub-header-item {
    margin-left: 10px;
}

.sub-header-host-icon {
    font-size: 22px;
    color: gray;
}

.sub-header-host-icon:nth-child(3) {
    margin-left: 15px;
}

.header-divider {
    border-bottom: solid lightgray 1px;
}

.header-divider--reduced {
    width: 500px;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .header-divider--reduced {
        width: 100%;
    }
}