.time-btn, 
.confirm-btn {
    width: 100%;
}
.selected.confirm-btn {
    display: inline;
    padding: 11px 20px;
    box-shadow: none;
}

.confirm-btn {
    text-align: center;
}

.display-none.display-none {
    display: none
}

.time-picker__empty-wrapper {
    background: #f2f2f2;
    text-align: center;
    padding: 135px 0;
    margin-top: 35px;
}

.time-picker__empty-text {
    margin: 0px 27%;
}

.time-picker button.time-btn {
    border-color: #d9d9d9;
    color: black;
    padding: 10px 20px;
}

.time-btn.selected {
    background: #f2f2f2;
}

.time-list {
    max-height: 500px;
    overflow: auto;
    width: 100%;
}

.time-button-wrapper {
    margin-bottom: 5px;
    display: flex;
}

.time-button {
    flex-basis: 100%;
}

.time-button--selected {
    flex-basis: 50%;
}

.time-button--selected:first-of-type {
    margin-right: 5px;
}

.time-button--selected:last-of-type {
    margin-left: 5px;
}

.time-picker__timezone {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: grey;
}

.time-picker__timezone:hover {
    text-decoration: underline;
    cursor: pointer;
}

.time-picker__timezone span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 20px);
    display: inline-block;
}

.time-picker__timezone::after {
    content: ' ';
    border: 5px solid transparent;
    border-top-color: grey;
    display: inline-block;
    margin-left: 6px;
}

.time-picker__timezone-select {
    width: 100%;
    /* Don't use display:none because we need the select options to position correctly. */
    opacity: 0;
    height: 0;
}

.time-picker__empty-text-direction--mobile {
    display: none;
}

@media screen and (max-width: 950px) { 
    .time-picker__empty-text-direction--mobile {
        display: inline;
    }

    .time-picker__empty-text-direction--desktop {
        display: none;
    }
}