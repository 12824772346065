.app-wrapper {
    background-color: #fff;
    margin: 30px auto;
    max-width: 950px;
    border-radius: 20px;
    box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 65px;
    position: relative;
    min-height: 600px;
}

.app-inner-wrapper {
    overflow: hidden;
}

.col-wrapper {
    display: flex;
}

.col-wrapper__column-left {
    width: 50%;
    margin-right: 10px;
}

.col-wrapper__column-right {
    width: 50%;
    margin-left: 10px;
}

.col-wrapper--wider .col-wrapper__column-left {
    width: 60%;
}

.col-wrapper--wider .col-wrapper__column-right {
    width: 40%;
}

.text-align-center {
    text-align: center;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 950px) {
    body {
        background-color: #fff;
    }
    .app-wrapper {
        margin: 0;
        border-radius: 0;
        padding: 60px 20px;
        box-shadow: none;
    }

    .mobile-only {
        display: block;
    }

    .col-wrapper {
        display: block;
    }

    .col-wrapper__column-left.col-wrapper__column-left {
        width: 100%;
        margin-right: 0;
    }

    .col-wrapper__column-right.col-wrapper__column-right {
        width: 100%;
        margin-left: 0;
    }
}