.bookingFormPage-wrapper {
    opacity: 0;
    transition: all .75s ease;
    width: 1px;
    margin-left: 100%;
}
.slide-in-right {
    opacity: 1;
    width: 100%;
    margin-left: 0;
}

.appt-summary {
    margin-bottom: 20px;
}

.booking-form-page__cancellation-message {
    margin-top: 10px;
}

.booking-form-page__loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
    border-radius: 20px;
    opacity: 0;
    animation: loaderBgVisibility .3s ease-in .3s forwards;
}

@keyframes loaderBgVisibility {
    to {
      opacity: .7;
    }
}