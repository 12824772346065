.calendar th, .calendar td {
    padding: 10px;
}
.calendar th {
    font-weight: normal;
}

.calendar td {
    height: 37px;
    width: 37px;
    text-align: center;
}

.calendar table {
    border-spacing: 0px;
    border-top: solid 1px #d9d9d9;
}

.calendar thead, .calendar thead tr  {
    background-color: #f2f2f2;

}

.calendar td,
.calendar-button {
    cursor: pointer;
}

.calendar-button {
    font-size: 25px;
    margin-top: 5px;
}

.calendar {
    font-family: 'Work Sans';
    position: relative
}

.calendar .header span:first-child {
    float: left;
}

.calendar .header span:last-child {
    float: right;
}

.calendar .header .month-title, 
.calendar .header span:first-child,
.calendar .header span:last-child {
    display: inline;
}

.calendar .cell:not(.disabled)  {
    border-radius: 50%;
    padding: 8px;
    width: 50%;
    outline: none;
}

.calendar .cell:not(.disabled):not(.hovered):not(.selected) {
    background-color: #eee;
    border: solid 1px #eee;
}

.calendar .selected.selected,
.calendar .hovered.hovered:not(.selected) {
    background-color: var(--primary-color);
}

.calendar .selected.selected:not(.disabled),
.calendar .hovered.hovered:not(.selected) {
    color: var(--text-color,#ffffff);
    border: solid 1px var(--primary-color);
}

.calendar .disabled.disabled.disabled {
    background-color: inherit;
    border-color: none;
    pointer-events: none;
    cursor: not-allowed;
}

.calendar tbody td[role="gridcell"] .cell {
    outline: none;
}

.calendar--fetching::before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(200,200,200);
    opacity: .1;
    border-radius: 10px;
}

@media screen and (max-width: 950px) {
    .calendar-grid-wrapper {
        justify-content: center;
    }
}

/* 390 is the size of iPhone 12 Pro. Reduce padding to avoid calendar from getting cut off */
@media screen and (max-width: 390px) {
    .calendar th, .calendar td {
        padding: 10px 9px;
    }
}

/* 375 is the size of iPhone SE. Reduce padding to avoid calendar from getting cut off */
@media screen and (max-width: 375px) {
    .calendar th, .calendar td {
        padding: 10px 7px;
    }
}