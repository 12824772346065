.apptSummaryBackButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.apptSummaryBackButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 30px;
}

.apptSummaryBackButton p {
    margin-left: -3px;
}

.appt-summary__timezone {
    color: grey;
}